import React from 'react';
import { Window, WindowContent, WindowHeader, Button } from 'react95';
import Gif from './pikachu-under-construction.gif';

const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const Main = () => (
  <Window style={{ width: 500, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
    <WindowHeader style={flex}>
      <span>himynameisdave.exe</span>
      <Button style={{ marginRight: '-6px', marginTop: '1px' }} size={'sm'} square>
        <span style={{ fontWeight: 'bold', transform: 'translateY(-1px)' }}>x</span>
      </Button>
    </WindowHeader>
    <WindowContent>
      <div style={{ ...flex, alignItems: 'flex-start', justifyContent: 'center' }}>
        <img src={Gif} alt="Under construction" />
        <div>
          <h2>Under construction!</h2>
          <p>
            Dave is currently hard at work rebuilding this world wide web page.
          </p>
          <p>
            In the meantime, why don't you visit him elsewhere on the interwebs, such as on <a href="https://twitter.com/himynameisdave" target="_blank">the twitter</a> or perhaps <a href="https://github.com/himynameisdave" target="_blank">the github</a>.
          </p>
        </div>
      </div>
    </WindowContent>
  </Window>
);

export default Main;
