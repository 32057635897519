import React, { Fragment } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { reset, themes } from 'react95';
import Main from './Main';

const ResetStyles = createGlobalStyle`
  ${reset}
`;

function App() {
  return (
    <Fragment>
      <ResetStyles />
        <ThemeProvider theme={themes.default}>
          <Main />
        </ThemeProvider>
    </Fragment>
  );
}

export default App;
